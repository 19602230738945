/* ===============
   FONTS
   =============== */

@font-face {
  font-family: Montserrat;
  src: url('../assets/fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: Montserrat-Bold;
  src: url('../assets/fonts/Montserrat-Bold.ttf');
}

@font-face {
  font-family: Montserrat-SemiBold;
  src: url('../assets/fonts/Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: Montserrat-Medium;
  src: url('../assets/fonts/Montserrat-Medium.ttf');
}

/* ===============
   ROOT VARIABLES
   =============== */

:root {
  --ion-font-family: 'Montserrat-Medium', sans-serif;
  --ion-color-primary: #142EBC;
  --ion-color-primary-rgb: 20, 46, 188;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #1228a5;
  --ion-color-primary-tint: #2c43c3;

  --ion-color-secondary: #527CD8;
  --ion-color-secondary-rgb: 82, 124, 216;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #486dbe;
  --ion-color-secondary-tint: #6389dc;

  --ion-color-tertiary: #263ED1;
  --ion-color-tertiary-rgb: 38, 62, 209;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #2137b8;
  --ion-color-tertiary-tint: #3c51d6;

  --ion-color-success: #05711a;
  --ion-color-success-rgb: 5, 113, 26;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #046317;
  --ion-color-success-tint: #1e7f31;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #9B1C1C;
  --ion-color-danger-rgb: 155, 28, 28;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #881919;
  --ion-color-danger-tint: #a53333;

  --ion-color-dark: #484848;
  --ion-color-dark-rgb: 72, 72, 72;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #3f3f3f;
  --ion-color-dark-tint: #5a5a5a;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;

  --ion-text-color: #484848;
  --ion-text-color-rgb: 72, 72, 72;

  --ion-color-step-50: #f6f6f6;
  --ion-color-step-100: #ededed;
  --ion-color-step-150: #e4e4e4;
  --ion-color-step-200: #dadada;
  --ion-color-step-250: #d1d1d1;
  --ion-color-step-300: #c8c8c8;
  --ion-color-step-350: #bfbfbf;
  --ion-color-step-400: #b6b6b6;
  --ion-color-step-450: #adadad;
  --ion-color-step-500: #a4a4a4;
  --ion-color-step-550: #9a9a9a;
  --ion-color-step-600: #919191;
  --ion-color-step-650: #888888;
  --ion-color-step-700: #7f7f7f;
  --ion-color-step-750: #767676;
  --ion-color-step-800: #6d6d6d;
  --ion-color-step-850: #636363;
  --ion-color-step-900: #5a5a5a;
  --ion-color-step-950: #515151;

  --ion-color-vp-dark-blue: #142EBC;
  --ion-color-vp-dark-blue-rgb: 20, 46, 188;
  --ion-color-vp-dark-blue-contrast: #ffffff;
  --ion-color-vp-dark-blue-contrast-rgb: 255, 255, 255;
  --ion-color-vp-dark-blue-shade: #1228a5;
  --ion-color-vp-dark-blue-tint: #2c43c3;

  --ion-color-vp-light-blue: #527CD8;
  --ion-color-vp-light-blue-rgb: 82, 124, 216;
  --ion-color-vp-light-blue-contrast: #ffffff;
  --ion-color-vp-light-blue-contrast-rgb: 255, 255, 255;
  --ion-color-vp-light-blue-shade: #486dbe;
  --ion-color-vp-light-blue-tint: #6389dc;

  --ion-color-vp-dark-red: #9B1C1C;
  --ion-color-vp-dark-red-rgb: 155, 28, 28;
  --ion-color-vp-dark-red-contrast: #ffffff;
  --ion-color-vp-dark-red-contrast-rgb: 255, 255, 255;
  --ion-color-vp-dark-red-shade: #881919;
  --ion-color-vp-dark-red-tint: #a53333;

  --ion-color-vp-light-red: #c94949;
  --ion-color-vp-light-red-rgb: 201, 73, 73;
  --ion-color-vp-light-red-contrast: #ffffff;
  --ion-color-vp-light-red-contrast-rgb: 255, 255, 255;
  --ion-color-vp-light-red-shade: #b14040;
  --ion-color-vp-light-red-tint: #ce5b5b;

  --ion-color-vp-dark-green: #05711a;
  --ion-color-vp-dark-green-rgb: 5, 113, 26;
  --ion-color-vp-dark-green-contrast: #ffffff;
  --ion-color-vp-dark-green-contrast-rgb: 255, 255, 255;
  --ion-color-vp-dark-green-shade: #046317;
  --ion-color-vp-dark-green-tint: #1e7f31;

  --ion-color-vp-light-green: #0ba704;
  --ion-color-vp-light-green-rgb: 11, 167, 4;
  --ion-color-vp-light-green-contrast: #ffffff;
  --ion-color-vp-light-green-contrast-rgb: 255, 255, 255;
  --ion-color-vp-light-green-shade: #0a9304;
  --ion-color-vp-light-green-tint: #23b01d;

  --ion-color-vp-gray: #D0D0D0;
  --ion-color-vp-gray-rgb: 208, 208, 208;
  --ion-color-vp-gray-contrast: #000000;
  --ion-color-vp-gray-contrast-rgb: 0, 0, 0;
  --ion-color-vp-gray-shade: #b7b7b7;
  --ion-color-vp-gray-tint: #d5d5d5;

  --ion-color-vp-purple: #575CE1;
  --ion-color-vp-purple-rgb: 87, 92, 225;
  --ion-color-vp-purple-contrast: #ffffff;
  --ion-color-vp-purple-contrast-rgb: 255, 255, 255;
  --ion-color-vp-purple-shade: #4d51c6;
  --ion-color-vp-purple-tint: #686ce4;

  --ion-color-vp-turquoise: #39B7DD;
  --ion-color-vp-turquoise-rgb: 57, 183, 221;
  --ion-color-vp-turquoise-contrast: #000000;
  --ion-color-vp-turquoise-contrast-rgb: 0, 0, 0;
  --ion-color-vp-turquoise-shade: #32a1c2;
  --ion-color-vp-turquoise-tint: #4dbee0;

  .btn-cookies {
    .alert-button-group {
      justify-content: center;
    }

    .alert-button {
      font-weight: bold;
    }
  }

  .alertCancel {

    --backdrop-opacity: 0.9;

    .alert-title {
      text-align: center;
      font-size: 15px;
      color: #3C72D5;
      font-weight: bold;
    }

    .alert-message {
      text-align: center;
      font-size: 14px;
      color: #9B99A9;
    }

    .alert-button {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 6px 16px #0000001A;
      border: 1px solid #3C72D5;
      border-radius: 16px;
      width: 29vw;
      height: 116px;

      & .alert-button-inner {
        font-size: 14px;
        font-weight: 900;
        color: #3C72D5;
        text-transform: uppercase;
      }
    }

    .alert-sub-title {
      text-align: center;
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 0px;
      color: #3C72D5;
      text-transform: uppercase;
    }

    .alert-button-inner.sc-ion-alert-md {
      justify-content: center;
    }

    .alert-wrapper.sc-ion-alert-md {
      border-radius: 24px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 15px 17px #02549A4E;
    }

    .alert-button-group.sc-ion-alert-md {
      padding-bottom: 20px;
    }

    .alert-wrapper {
      .alert-button-group {
        justify-content: center;
      }
    }

  }

}

/* ===============
   CUSTOM COLORS
   =============== */

.ion-color-vp-dark-blue {
  --ion-color-base: var(--ion-color-vp-dark-blue);
  --ion-color-base-rgb: var(--ion-color-vp-dark-blue-rgb);
  --ion-color-contrast: var(--ion-color-vp-dark-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-vp-dark-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-vp-dark-blue-shade);
  --ion-color-tint: var(--ion-color-vp-dark-blue-tint);
}

.ion-color-vp-light-blue {
  --ion-color-base: var(--ion-color-vp-light-blue);
  --ion-color-base-rgb: var(--ion-color-vp-light-blue-rgb);
  --ion-color-contrast: var(--ion-color-vp-light-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-vp-light-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-vp-light-blue-shade);
  --ion-color-tint: var(--ion-color-vp-light-blue-tint);
}

.ion-color-vp-dark-red {
  --ion-color-base: var(--ion-color-vp-dark-red);
  --ion-color-base-rgb: var(--ion-color-vp-dark-red-rgb);
  --ion-color-contrast: var(--ion-color-vp-dark-red-contrast);
  --ion-color-contrast-rgb: var(--ion-color-vp-dark-red-contrast-rgb);
  --ion-color-shade: var(--ion-color-vp-dark-red-shade);
  --ion-color-tint: var(--ion-color-vp-dark-red-tint);
}

.ion-color-vp-light-red {
  --ion-color-base: var(--ion-color-vp-light-red);
  --ion-color-base-rgb: var(--ion-color-vp-light-red-rgb);
  --ion-color-contrast: var(--ion-color-vp-light-red-contrast);
  --ion-color-contrast-rgb: var(--ion-color-vp-light-red-contrast-rgb);
  --ion-color-shade: var(--ion-color-vp-light-red-shade);
  --ion-color-tint: var(--ion-color-vp-light-red-tint);
}

.ion-color-vp-dark-green {
  --ion-color-base: var(--ion-color-vp-dark-green);
  --ion-color-base-rgb: var(--ion-color-vp-dark-green-rgb);
  --ion-color-contrast: var(--ion-color-vp-dark-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-vp-dark-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-vp-dark-green-shade);
  --ion-color-tint: var(--ion-color-vp-dark-green-tint);
}

.ion-color-vp-light-green {
  --ion-color-base: var(--ion-color-vp-light-green);
  --ion-color-base-rgb: var(--ion-color-vp-light-green-rgb);
  --ion-color-contrast: var(--ion-color-vp-light-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-vp-light-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-vp-light-green-shade);
  --ion-color-tint: var(--ion-color-vp-light-green-tint);
}

.ion-color-vp-gray {
  --ion-color-base: var(--ion-color-vp-gray);
  --ion-color-base-rgb: var(--ion-color-vp-gray-rgb);
  --ion-color-contrast: var(--ion-color-vp-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-vp-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-vp-gray-shade);
  --ion-color-tint: var(--ion-color-vp-gray-tint);
}

.ion-color-vp-purple {
  --ion-color-base: var(--ion-color-vp-purple);
  --ion-color-base-rgb: var(--ion-color-vp-purple-rgb);
  --ion-color-contrast: var(--ion-color-vp-purple-contrast);
  --ion-color-contrast-rgb: var(--ion-color-vp-purple-contrast-rgb);
  --ion-color-shade: var(--ion-color-vp-purple-shade);
  --ion-color-tint: var(--ion-color-vp-purple-tint);
}

.ion-color-vp-turquoise {
  --ion-color-base: var(--ion-color-vp-turquoise);
  --ion-color-base-rgb: var(--ion-color-vp-turquoise-rgb);
  --ion-color-contrast: var(--ion-color-vp-turquoise-contrast);
  --ion-color-contrast-rgb: var(--ion-color-vp-turquoise-contrast-rgb);
  --ion-color-shade: var(--ion-color-vp-turquoise-shade);
  --ion-color-tint: var(--ion-color-vp-turquoise-tint);
}
