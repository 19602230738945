/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import "~@ng-select/ng-select/themes/default.theme.css";

/* ===============
   STANDARD IONIC COMPONENTS AND TAGS
   =============== */

ion-content {
  --padding-end: 24px;
  --padding-start: 24px;
}

ion-toolbar {
  background-image: linear-gradient(to left, var(--ion-color-vp-dark-blue), var(--ion-color-vp-light-blue));
  --background: transparent;
  --color: white;
  height: 65px;
  display: flex;
  --border-width: 0 !important;
}

ion-title {
  padding-left: 40px;
  font-size: 18px;
}

ion-back-button {
  --icon-font-size: 16px;
}

ion-header {
  box-shadow: #0014ff1a 0 10px 20px 0;
}

ion-button {
  --border-radius: 14px;
}

ion-avatar.asset-page-wrapper {
  width: 150px;
  height: 150px;
  background-color: var(--ion-color-light-tint);
  border-radius: 30px;
  margin: 0 auto;

  .asset-page-image {
    border-radius: 0;
    object-fit: none;
    overflow: inherit;
  }
}

ion-input {
  --background: white;
  --color: var(--ion-color-dark);
  --placeholder-color: var(--ion-color-vp-gray);
  --padding-bottom: 6px;
  --padding-end: 6px;
  --padding-start: 6px;
  --padding-top: 6px;
  height: 60px;
}

ion-item {
  --border-width: 2px;
  --border-color: #ECEDF2;
  --border-radius: 14px;
  --inner-padding-bottom: 0px;
  --inner-padding-end: 6px;
  --inner-padding-start: 6px;
  --inner-padding-top: 0px;

  ion-button.mini-button {
    height: 48px;
    width: 48px;
  }
}

ion-button.mini-button {
  height: 40px;
  width: 40px;
  --border-radius: 14px;
  border-radius: 14px;
  font-size: 11px;
  box-shadow: -1px 1px 20px 2px #0014FF1A;
  background-image: linear-gradient(to left, var(--ion-color-vp-dark-blue), var(--ion-color-vp-light-blue));
  --background: transparent;
}

ion-button.mini-button.disabled-1 {
  background: var(--ion-color-vp-gray);
  --box-shadow: none;
  opacity: 1;
}

ion-button.mini-button.blue-gradient-1 {
  background-image: linear-gradient(to left, var(--ion-color-vp-turquoise), var(--ion-color-vp-purple));
}

ion-button.mini-button.green-gradient-1, ion-button.medium-button.green-gradient-1, ion-button.large-circle-button.green-gradient-1 {
  box-shadow: -1px 1px 20px 2px #00FF0A1A;
  background-image: linear-gradient(to left, var(--ion-color-vp-dark-green), var(--ion-color-vp-light-green));
  --background: linear-gradient(to left, var(--ion-color-vp-dark-green), var(--ion-color-vp-light-green));
}

ion-button.medium-button {
  height: 50px;
  min-width: 140px;
  --border-radius: 14px;
  border-radius: 14px;
  font-size: 14px;
  --box-shadow: -1px 1px 20px 10px #0014FF1A;
  background-image: linear-gradient(to left, var(--ion-color-vp-dark-blue), var(--ion-color-vp-light-blue));
  --background: transparent;
}

@media(max-width:374px) {
  ion-button.medium-button {
    width: 110px;
    min-width: 110px;
    height: 40px;
  }
}

ion-button.medium-button.disabled-1 {
  --box-shadow: -1px 1px 20px 10px #5F5F5F1A;
  background: var(--ion-color-vp-gray);
  opacity: 1;
}

ion-button.medium-button.red-gradient-1, ion-button.large-circle-button.red-gradient-1 {
  --box-shadow: -1px 1px 20px 10px #CC25251A;
  background-image: linear-gradient(to left, var(--ion-color-danger), var(--ion-color-vp-light-red));
  --background: linear-gradient(to left, var(--ion-color-danger), var(--ion-color-vp-light-red));
}

ion-button.large-circle-button {
  width: 100px;
  height: 100px;
  --border-radius: 64px;
  border-radius: 64px;
  --box-shadow: -1px 1px 20px 10px #5F5F5F1A;
  --background: var(--ion-color-medium);
}

.toast-text {
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ng-select.select-tenant {
  .ng-select-container, .ng-select-focused {
    height: 60px;
    padding: 10px;
    border-radius: 14px;
    background: transparent;
    border: 2px #ECEDF2 solid;
    color: white;

    .ng-value-container .ng-placeholder {
      font-size: 12px;
      font-weight: 600;
      color: white;
      text-transform: uppercase;
    }
  }

  .ng-dropdown-panel .ng-dropdown-panel-items {
    padding: 10px;

    .ng-option {
      color: var(--ion-color-tertiary);
      font-weight: 600;
      text-align: left;
      text-transform: uppercase;
    }
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel
.ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: var(--ion-color-tertiary);
  background-color: #263ed12e;
  border-radius: 7px;
}

.ng-dropdown-panel.ng-select-bottom {
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: inherit;
  box-shadow: none;
}

*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

video::-webkit-media-controls-start-playback-button {
  display: none !important;
}

video::-webkit-media-controls {
  opacity: 0;
}

.video-background::-webkit-media-controls-panel,
.video-background::-webkit-media-controls-start-playback-button {
  display: none !important;
}

video.chat-video::-webkit-media-controls-start-playback-button {
  display: revert !important;
  -webkit-appearance: revert;
}

video.chat-video::-webkit-media-controls-start-playback-button {
  display: revert !important;
}

video.chat-video::-webkit-media-controls {
  opacity: 1;
}

.chat-video::-webkit-media-controls-panel,
.chat-video::-webkit-media-controls-start-playback-button {
  display: revert !important;
}

video.chat-video::-webkit-media-controls-play-button {
  display: revert !important;
  -webkit-appearance: revert;
}

.logo-chip {
  max-width: 200px;
  max-height: 34px;
  border: 2px solid white;
  border-radius: 23px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 12px;
  color: white;
  font-weight: 600;
  padding: 9px 18px 9px 18px;
}

.logo {
  width: 140px;
}

.info-card {
  min-height: 320px;
  background-color: white;
  border-radius: 20px;
  box-shadow: -1px 1px 15px 5px #0014FF1A;
  padding: 10px 10px 20px 10px;
}

.small-info-card {
  height: 180px;
  background-color: white;
  border-radius: 20px;
  box-shadow: -1px 1px 15px 5px #0014FF1A;
  padding: 30px 18px 30px 18px;

  ion-button {
    margin-bottom: 20px;
  }

  .title {
    font-family: Montserrat-Bold, sans-serif;
    font-size: 16px;
  }

  .subtitle {
    font-family: Montserrat-SemiBold, sans-serif;
    font-size: 12px;
    color: #A0A0A0;
    margin-top: 8px;
  }
}

.product-info-card {
  min-height: 160px;
  background-color: white;
  border-radius: 20px;
  box-shadow: -1px 1px 15px 5px #0014FF1A;
  padding: 20px 12px 12px 12px;

  .title {
    font-family: Montserrat-Bold, sans-serif;
    font-size: 16px;
    margin-bottom: 12px;
  }
}

/* ===============
   DATASHEET TABLES
   =============== */

.datasheet-wrapper {
  background: var(--ion-color-light-tint);
  border-radius: 20px;
  padding-bottom: 15px;
  margin-top: 10px;

  hr {
    background-color: #ECEDF2;
  }

  .title {
    font-family: Montserrat-Bold, sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    color: var(--ion-color-tertiary);
    padding: 15px 15px 15px 15px;
  }

  .feature-name {
    font-family: Montserrat-SemiBold, sans-serif;
    font-size: 12px;
    color: #A0A0A0;
    margin-top: 8px;
  }

  .feature-value {
    min-width: 220px;
    background: #ECEDF2;
    border-radius: 20px;
    font-family: Montserrat-Bold, sans-serif;
    font-size: 16px;
    padding: 6px 12px 6px 12px;
    margin-top: 8px;
  }
}

/* ===============
   UTIL CLASSES
   =============== */

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

/* ===============
   CUSTOM COMPONENTS - ACTION SHEET
   =============== */

.eps-action-sheet {
  --backdrop-opacity: 0.7;
}

.eps-action-sheet .action-sheet-group {
  background: white;
}

.eps-action-sheet .action-sheet-button {
  padding-inline-start: 20px;
  padding-inline-end: 20px;
  padding-top: 14px;
  padding-bottom: 14px;
}

.eps-action-sheet .action-sheet-button .action-sheet-button-inner {
  justify-content: start;
  font-size: 16px;
  color: #484848;
}

.eps-action-sheet .finish-button .action-sheet-button-inner {
  color: var(--ion-color-danger);
  justify-content: center;
  text-transform: uppercase;
}

.eps-action-sheet .action-sheet-button .action-sheet-button-inner .action-sheet-icon {
  padding-right: 20px;
  font-size: 24px;
  color: var(--ion-color-tertiary);
}

.eps-action-sheet .action-sheet-group-cancel .action-sheet-button {
  height: 50px;
  background: linear-gradient(to left, var(--ion-color-danger), var(--ion-color-vp-light-red));
}

.eps-action-sheet .action-sheet-cancel.sc-ion-action-sheet-ios::after {
  background: none;
  opacity: 1;
}

.eps-action-sheet .action-sheet-group-cancel .action-sheet-button .action-sheet-button-inner {
  justify-content: center;
  font-size: 14px;
  color: white;
  text-transform: uppercase;
}

.eps-action-sheet .button-activated {
  background-color: rgba(56, 128, 255, 0.1);
  font-weight: bold;
}

/* adjust the CSS to your liking */

video-player-container {
  width: 100%;
}

video-player {
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
}
